<template>
  <a-spin :spinning="loading">
    <a-row v-if="isProductPresent">
      <a-col :span="12">
        <div
          v-for="(product, index) in data"
          :key="product.id"
        >

          <a-divider v-if="index > 0" />

          <a-descriptions
            :column="1"
            class="simiot-descriptions simiot-descriptions-max-6"
          >
            <a-descriptions-item :label="`后续套餐 ${index+1}`">
              {{ product.product_name }}
              <a-button v-if="product.is_can_unsubscribe" type="primary" @click="unsubscribeSimCardPackage(product.id)">退订</a-button>
            </a-descriptions-item>
            <a-descriptions-item label="用户业务模式">
              {{ product.user_business_type }}
            </a-descriptions-item>
            <a-descriptions-item label="订购时间">
              {{ product.subscribed_at }}
            </a-descriptions-item>
            <a-descriptions-item label="套餐周期数">
              {{ product.cycles }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </a-col>
    </a-row>
    <a-empty v-else />
  </a-spin>
</template>

<script>
import { findSimCardFuturePackages, unsubscribeSimCardPackage } from '@/api/sim_card'

export default {
  name: 'ShowSimCardFuturePackages',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: []
    }
  },
  computed: {
    isProductPresent() {
      return this.data.length > 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findSimCardFuturePackages(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },
    // 退订套餐
    unsubscribeSimCardPackage(id) {
      const vm = this
      this.$confirm({
        title: '确定退订此套餐吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          var data = {
            sim_cards_product_id: id
          }

          unsubscribeSimCardPackage(vm.id, data).then(() => {
            vm.fetchData()
          })
        }
      })
    }
  }
}
</script>
